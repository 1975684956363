import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Alert,
  Card,
  CardContent,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import envConfig from "../../config";
import { getCookie } from "../../utils/api";

const TwoFactorSetup = () => {
  const { dbId, lang } = useParams();
  const navigate = useNavigate();
  const prefix = `/${dbId}/${lang}/`;

  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [manualEntryKey, setManualEntryKey] = useState("");
  const [hashKey, setHashKey] = useState("");
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [hasEnabled2FA, setHasEnabled2FA] = useState(false);

  useEffect(() => {
    const fetch2FASettings = async () => {
      const url = `https://${envConfig.apiDev1}/api/v3/en-au/user2fa/generate-2fa-security-settings?Size=160`;

      try {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "127.0.0.2",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "browser-agent": window.navigator.userAgent,
            "session-id": getCookie("sessionId"),
            "api-key": getCookie("apiKey") + "-0",
          },
          method: "GET",
        });
        const data = await response.json();
        //

        if (response.ok) {
          setQrCodeUrl(data.QrCodeSetupImageUrl);
          setManualEntryKey(data.ManualEntryKey);
          setHashKey(data.Secret2FAKey);
          setHasEnabled2FA(data.CurrentUser2FAHasEnabledYN === "Y");
        } else {
          enqueueSnackbar("Failed to generate 2FA settings", {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar("Failed to generate 2FA settings", {
          variant: "error",
        });
      }
    };

    fetch2FASettings();
  }, []);

  const handleEnable2FA = async () => {
    const url = `https://${envConfig.apiDev1}/api/v3/en-au/user2fa/enable-2fa-security`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "127.0.0.2",
          "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "browser-agent": window.navigator.userAgent,
          "session-id": getCookie("sessionId"),
          "api-key": getCookie("apiKey") + "-0",
        },
        body: JSON.stringify({
          Secret2FAKey: hashKey,
          User2FACode: twoFactorCode,
        }),
      });
      const data = await response.json();

      if (response.ok && data.User2FAEnabledYN === "Y") {
        enqueueSnackbar("2FA has been enabled successfully.", {
          variant: "success",
        });
        navigate("/user-dashboard");
      } else {
        enqueueSnackbar(data?.ErrorMessage || "Failed to enable 2FA", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("Failed to enable 2FA", { variant: "error" });
    }
  };

  const handleDisable2FA = async () => {
    const url = `https://${envConfig.apiDev1}/api/v3/en-au/user2fa/disable-2fa-security`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "127.0.0.2",
          "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "browser-agent": window.navigator.userAgent,
          "session-id": getCookie("sessionId"),
          "api-key": getCookie("apiKey") + "-0",
        },
      });
      const data = await response.json();

      if (response.ok && data.User2FADisabledYN === "Y") {
        enqueueSnackbar("2FA has been disabled successfully.", {
          variant: "success",
        });
        navigate("/user-dashboard");
      } else {
        enqueueSnackbar(data?.ErrorMessage || "Failed to disable 2FA", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("Failed to disable 2FA", { variant: "error" });
    }
  };

  return (
    <Box sx={{ pb: 3 }}>
      <Box>
        <Typography variant="h3" align="center" gutterBottom>
          Setup 2FA on your device
        </Typography>
      </Box>
      <Card sx={{ maxWidth: 600, margin: "auto", mt: 4, mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Set up 2FA
          </Typography>
          <Typography paragraph>
            We are using 2-factor authentication to protect your account. Each
            day, after you enter your password, you will be asked for a code
            generated from the app. We recommend you install an Authenticator
            app on your phone and follow the steps below to set it up so your
            login is instant. If you don’t, you will be receiving SMS with a
            code, which may be delayed.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            We recommend using <b>Google Authenticator</b>
          </Typography>
          <Box sx={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
            <a href="https://itunes.apple.com/au/app/google-authenticator/id388497605?mt=8">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/9/91/Download_on_the_App_Store_RGB_blk.svg"
                alt="Download on the App Store"
                style={{ height: "40px" }}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                alt="Get it on Google Play"
                style={{ height: "40px" }}
              />
            </a>
          </Box>
          <Typography paragraph>
            If you will use Google Authenticator:
          </Typography>
          <Typography paragraph>
            <strong>Step 1:</strong> Install your Google Authenticator App and
            open it
          </Typography>
          <Typography paragraph>
            <strong>Step 2:</strong> Click on the Plus sign in the bottom
            right-hand corner, and:
          </Typography>
          <ul>
            <li>
              If you are not logged in on the phone now, select{" "}
              <b>‘Scan a QR Code’</b>, and point your camera to the below code,
              so it fits into the frame.
            </li>
          </ul>
          {qrCodeUrl && (
            <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
              <img src={qrCodeUrl} alt="QR Code" />
            </Box>
          )}
          <ul>
            <li>
              If you are logged in on your phone, use <b>‘Enter a setup key’</b>{" "}
              instead, and copy the code below into Your key field. You can name
              the code <em>‘Exacc’</em>.
            </li>
          </ul>
          <TextField
            fullWidth
            label="Hash Code"
            value={manualEntryKey}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <CopyToClipboard text={manualEntryKey}>
                  <Button
                    variant="contained"
                    sx={{ mr: -1.5, ml: "10px", width: "130px" }}
                  >
                    Copy Hash
                  </Button>
                </CopyToClipboard>
              ),
            }}
            sx={{ marginBottom: "20px" }}
          />
          <Typography paragraph>
            <strong>Step 3:</strong> The app will display a six-digit code on
            your phone. Please enter the code below and click{" "}
            <strong>‘Save’</strong>.
          </Typography>
          <TextField
            fullWidth
            label="2FA Code"
            value={twoFactorCode}
            onChange={(e) => setTwoFactorCode(e.target.value)}
            sx={{ marginBottom: "20px" }}
          />
          <Box sx={{ mb: 2, display: "flex", gap: "10px" }}>
            <Button variant="contained" onClick={handleEnable2FA}>
              Save
            </Button>
            <Button variant="outlined" onClick={handleDisable2FA}>
              Remove 2FA
            </Button>
          </Box>
          {hasEnabled2FA && (
            <Alert severity="warning">
              Your user has the active 2FA security enabled already. By saving,
              you will overwrite existing 2FA setup and old 2FA will stop
              working.
            </Alert>
          )}
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => {
              navigate("/user-dashboard");
            }}
          >
            Back to Dashboard
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TwoFactorSetup;
