import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "../../../context/ThemeContext";
import Logo from "../../../assets/exacc-logo.png";
import envConfig from "../../../config";
import { enqueueSnackbar } from "notistack";
import {
  getCookie,
  getLoginSecured,
  LOGIN_API_OLIVS_APP_URL,
} from "../../../utils/api";
import Cookies from "js-cookie";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "olivs-api-real-ip": "31.42.0.186",
  "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
  "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
  "api-key": "OlivsAuth",
};

const RegisterSuccessPanel = () => {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [updatingEmail, setUpdatingEmail] = useState(false);
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [loginSecured, setLoginSecured] = useState("");
  const [emailResent, setEmailResent] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const noFullFit = useMediaQuery("(max-width: 1300px)");
  const isTablet = useMediaQuery("(max-width: 1028px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleVerification = async () => {
    setLoading(true);
    const url = `https://${envConfig.apiDev1}/api/v3/en-au/users/verify-login-email-by-code-async?BaseHostURL=${envConfig.loginUrl}`;
    const body = {
      LoginSecuredHash: loginSecured,
      VerificationCode: code,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "115.70.37.103",
          "olivs-api-computer-name": "BTM-WS2019",
          // "session-id": getCookie("sessionId"),
          // "browser-agent": window.navigator.userAgent,
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "api-key": "OlivsAuth",
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      if (data.SuccessYN === "Y") {
        const generateTokenUrl =
          LOGIN_API_OLIVS_APP_URL +
          `en-au/system/generate-new-api-token-details?BaseHostURL=${envConfig.loginUrl}`;

        const generateTokenData = JSON.stringify({
          LoginSecuredHash: loginSecured,
        });

        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "115.70.37.103",
          "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "api-key": "OlivsAuth",
        };

        const generateTokenResponse = await fetch(generateTokenUrl, {
          method: "POST",
          headers: {
            ...headers,
            "browser-agent": window.navigator.userAgent,
          },
          body: generateTokenData,
        });

        const generateTokenJson = await generateTokenResponse.json();

        if (generateTokenJson?.SuccessYN === "Y") {
          Cookies.set("apiKey", generateTokenJson.ApiKey, { path: "/" });
          Cookies.set("sessionId", generateTokenJson.SessionID, { path: "/" });
          // document.cookie = `loginEmail=${email}; path=/`;

          // Cookies.set("databaseId", "0", { path: "/" });
          Cookies.set(
            "apiKeyExpirationUTCDate",
            generateTokenJson.ApiKeyExpiryUTC.slice(0, -1),
            {
              path: "/",
            }
          );
          navigate("/verify-phone", {
            state: {
              loginSecuredHash: loginSecured,
              phone: generateTokenJson?.MobPhoneNo,
              apiKey: generateTokenJson?.ApiKey,
              sessionId: generateTokenJson?.SessionID,
            },
          });
        }
      } else if (data?.SuccessYN === "N") {
        enqueueSnackbar(data?.ErrorMessage, { variant: "error" });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      (!location.state?.firstName || !location.state?.email) &&
      (!login || !email)
    ) {
      navigate("/register");
    } else {
      if (!login || !email) {
        setLogin(location.state?.firstName);
        setEmail(location.state?.email);
        setLoginSecured(location.state?.loginSecured);
      }
    }
  }, [location.state, login, email, navigate]);

  const handleResendEmail = async () => {
    if (loadingResend) return;
    setLoadingResend(true);
    const data = JSON.stringify({
      LoginSecuredHash: loginSecured,
    });

    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/users/send-verification-email?basehosturl=${envConfig.loginUrl}`,
        {
          method: "POST",
          headers: headers,
          body: data,
        }
      );

      const responseData = await response.json();
      if (responseData.EmailSentYN === "Y") {
        enqueueSnackbar("Please check your email shortly", {
          variant: "success",
        });
        setEmailResent(true);
      } else {
        enqueueSnackbar(responseData?.ErrorMessage, { variant: "error" });
      }
      setLoadingResend(false);
    } catch (error) {
      setLoadingResend(false);
      console.error("Error:", error);
    }
  };

  const handleUpdateEmail = async () => {
    const url = `https://${envConfig.apiDev1}/api/v3/en-au/users/change-login-email?BaseHostURL=${envConfig.loginUrl}`;

    const data = JSON.stringify({
      LoginSecuredHash: loginSecured,
      NewLoginEmail: newEmail,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: data,
      });

      const responseData = await response.json();
      if (responseData.SuccessYN === "Y") {
        setUpdatingEmail(false);
        setEmail(newEmail);
        // Update loginSecured to the new value if provided by the response
        setLoginSecured(responseData.NewLoginSecuredHash || "");
      } else {
        enqueueSnackbar(responseData.ErrorMessage, { variant: "error" });
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
    }
  };

  return (
    <Box
      sx={{
        mx: isMobile ? "auto" : "unset",
        ml: isMobile ? "auto" : isTablet ? "48px" : noFullFit ? 10 : 16,
        px: isMobile ? 5 : "unset",
      }}
    >
      <img
        onClick={() => {
          navigate(`/`);
        }}
        height={50}
        style={{ cursor: "pointer", position: "absolute", top: "64px" }}
        src={Logo}
        alt="Logo"
      />
      <Box sx={{ mt: "150px" }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            color: isDarkMode ? "#7D8CAA" : "#495670",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Inter",
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "133.4%",
          }}
        >
          Create Your Account
        </Typography>

        <Box
          sx={{
            mt: isMobile ? "20px" : "100px",
            borderBottom: "1px solid #EDEDED",
            paddingBottom: "39px",
            width: isMobile ? "100%" : isTablet ? 380 : 480,
          }}
        >
          <Typography sx={{ mb: 3 }}>Welcome, {login}!</Typography>
          <Typography sx={{ mb: 3 }}>
            Let's verify your e-mail: <b>{email}</b>
          </Typography>
          <Typography>
            Please check your inbox, we sent you a message with a code. Enter
            the code below:
          </Typography>
          <TextField
            type="tel"
            variant="outlined"
            margin="normal"
            value={code}
            onChange={(e) => {
              const newValue = e.target.value;
              if (/^\d*$/.test(newValue) && newValue.length <= 6) {
                setCode(newValue);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || loading) {
                handleVerification();
              }
            }}
            inputProps={{
              style: {
                textAlign: "center",
                fontSize: "16px",
                letterSpacing: "5px",
              },
            }}
            sx={{
              width: "100%",
              input: { minHeight: "39px" },
              "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                {
                  WebkitAppearance: "none",
                  margin: 0,
                },

              "input[type=number]": {
                MozAppearance: "textfield",
              },
            }}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading || code.length !== 6}
            onClick={handleVerification}
            sx={{ mt: 3, mb: 0, width: isMobile ? "100%" : 480 }}
          >
            Verify
          </Button>

          {!loginSecured ? (
            <></>
          ) : !updatingEmail ? (
            <Box sx={{ mb: 1, mt: 1 }}>
              <Link
                className="link"
                onClick={() => setUpdatingEmail(true)}
                style={{
                  color: isDarkMode ? "#AAD269" : "#849F23",
                  marginTop: 0,
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 700,
                  letterSpacing: "0.4px",
                  textTransform: "capitalize",
                  textDecoration: "none",
                }}
              >
                Edit E-mail Address
              </Link>
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyItems: "center" }}>
              <TextField
                variant="outlined"
                margin="normal"
                label="Email Address"
                inputProps={{
                  autoComplete: "off",
                }}
                required
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                sx={{
                  width: 240,
                  mt: 0,
                  mb: 0,
                  label: { fontSize: 16, marginTop: 0 },
                }}
              />
              <Button
                onClick={handleUpdateEmail}
                sx={{ height: "40px", ml: 1 }}
                variant="secondary"
              >
                Update
              </Button>
              <Button
                onClick={() => setUpdatingEmail(false)}
                sx={{ height: "40px", ml: 1 }}
                variant="tertiary"
              >
                Cancel
              </Button>
            </Box>
          )}

          <Typography sx={{ mb: 4 }}>
            Can't find the message? Check your spam folder, and add our address:{" "}
            <a
              className="link"
              style={{ color: isDarkMode ? "#AAD269" : "#849F23" }}
              href="mailto:sender@app.exacc.au"
            >
              sender@app.exacc.au
            </a>{" "}
            to your safe list. We will only send important notifications from
            this e-mail.
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 2,
            mb: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {emailResent ? (
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Please check your email shortly.
            </Typography>
          ) : (
            <>
              <Link
                onClick={handleResendEmail}
                className="link"
                style={{
                  color: loadingResend ? "gray" : "#849F23",
                  marginLeft: 10,
                  marginTop: 0,
                  cursor: loadingResend ? "default" : "pointer",
                  fontWeight: 700,
                  fontSize: 14,
                  letterSpacing: "0.4px",
                  textTransform: "capitalize",
                  textDecoration: loadingResend ? "none !imporant" : "none",
                }}
              >
                Send E-Mail Again
              </Link>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterSuccessPanel;
